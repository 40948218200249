:root {
  --first-color: #304c66;
  --second-color: #7da4c9;
  --third-color: #bdc1c5;
  --fourth-color: #ffffff;
  --fifth-color: #444749;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

body {
  /* color: var(--first-color); */
  background-color: var(--fourth-color);
  font-family: "Montserrat";
  width: 100%;
  overflow-x: hidden;
}

/* navigation style start */
header {
  padding: 20px 0 20px 0;
  width: 100%;
  border-bottom: solid 10px var(--first-color);
}

.logo{
  width: 30%;
}

.header-box {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--fourth-color);
}

.header-box div {
  display: flex;
  justify-content: center;
  align-content: center;
}

header nav {
  margin: 10px 15px;
}

header nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

header nav ul li a {
  padding: 0 40px;
  font-size: 16px;
  color: var(--first-color);
  letter-spacing: 4px;
}

header nav ul li a:hover {
  color: var(--second-color);
  text-decoration: none;
}

.nav-active {
  color: var(--second-color);
}

.about {
  display: flex;
  border-bottom: 1px solid var(--first-color);
}

.design h1, .webdev h1, .contact h1 {
  color: var(--first-color);
}

.resume p a, .design p a, .webdev p a {
  color: inherit;
  text-decoration: underline;
}

.container h5:hover {
  color: var(--second-color);
}

.about-img, .img {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
}

.img:hover {
  opacity: .7;
}

.img-border {
  border-bottom: 5px solid var(--third-color);
  border-top: 5px solid var(--third-color);
}

.webdev-button {
  padding: 5px 8px;
  font-weight: normal;
  font-size: auto;
  background-color: var(--first-color);
}

.webdev-button:hover {
  opacity: .5;
}

.webdev-border {
  border-left: 1px solid var(--third-color);
  border-right: 1px solid var(--third-color);
  border-bottom: 1px solid var(--third-color);
  border-radius: 0 0 10px 10px;
  margin: 0 8% 8% 8%;
}

.contact-button {
  max-width: 100px;
}

.button:hover {
  opacity: .7;
}

.footer {
  background-color: var(--first-color);
  display: flex;
  justify-content: center;
}

.icon {
  width: 30px;
  color: var(--fourth-color);
}

.icon:hover {
  opacity: .5;
}

/* MEDIA QUERY FOR 980PX SCREENS AND SMALLER */
@media screen and (max-width: 980px) {
  
  header nav ul li a {
    font-size: 14px;
    padding: 0 30px;
    letter-spacing: 2px;
  }

}

/* MEDIA QUERY FOR 768PX SCREENS AND SMALLER */
@media screen and (max-width: 768px) {
  
  .logo {
    width: 35%;
  }
  
  header nav ul li a {
    padding: 0 15px;
    font-size: 12px;
    letter-spacing: 2px;
  }

}

/* MEDIA QUERY FOR 575PX SCREENS AND SMALLER */
@media screen and (max-width: 575px) {

  body {
    overflow-x: hidden;
  }
  
  .logo {
    width: 50%;
  }

  .header-box div {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  header nav ul {
    flex-direction: column;
  }

  header nav ul li a {
    font-size: 18px;
    letter-spacing: 4px;
    padding: 0;
    margin: 0;
  }

  .design h1, .webdev h1, .contact h1 {
    font-size: 24px;
  }

  .resume h1 {
    font-size: 28px;
  }

}
